// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}
.search-label{
  width:100%;
}

.mapboxgl-popup-content{
 // background-color: #0a29f5 !important;
  font-size: 0.75em;
  font-weight: bold;
  height: 32px;



}

.mapboxgl-popup-content p{
  // background-color: #0a29f5 !important;
  white-space: nowrap;
  color: #403d85;
}
.mapboxgl-popup-close-button{
  visibility: hidden;
}
#search-bar-0{
  width:100%;
  background-color: #f3f4f7;
}
#search-bar-0 .input{
  width:100%;
  background-color: #f3f4f7;
}
.react-switch {
  vertical-align: middle;
  margin-left: 15px;
  margin-right: 15px;

}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 1000ms ease-in-out;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
