// 
// tasks.scss
//

#tache{
    position: relative; /* For positioning the pseudo-element */
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    border-radius:5px;
    background-color: #f9f9f9;
}

#tache::before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Create the box shadow at expanded size. */
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.4);

    /* Hidden by default. */
    opacity: 0;
    transition: opacity 500ms;
    border-radius:5px;
    background-image: linear-gradient(to right bottom, #f9f9f9, #f5f6fb, #eef3fd, #e4f1ff, #d8f0ff);
}

#tache:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
    border-radius: 5px;
}
#installation{

    position: relative; /* For positioning the pseudo-element */
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

#installation::before {
    /* Position the pseudo-element. */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    /* Create the box shadow at expanded size. */
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);

    /* Hidden by default. */
    opacity: 0.1;
    transition: opacity 1000ms;
}

#installation:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
}


/*#installation:hover{
    background-color: #d6d9e0;
    cursor: pointer;
}*/
.board {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
}

.tasks {
    display: inline-block;
    width: 22rem;
    padding: 0 1rem 1rem 1rem;
    vertical-align: top;
    margin-bottom: $grid-gutter-width;
    background-color: $card-bg;
    @include border-radius($border-radius-sm);

    &.tasks:not(:last-child) {
        margin-right: 1.25rem;
    }

    .card {
        white-space: normal;
        margin-top: 1rem;
    }

    .task-header {
        background-color: $card-bg;
        padding: 1rem;
        margin: 0 -1rem;
        @include border-radius($border-radius-sm);
    }
}

.task-list-items {
    min-height: 100px;
    position: relative;

    .card {
        cursor: pointer;
    }

    &:before {
        content: "No Tasks";
        position: absolute;
        line-height: 110px;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
}


// Task add modal
.task-modal-content {
    .form-control-light {
        background-color: lighten($gray-200, 2.5%) !important;
        border-color: lighten($gray-200, 2.5%) !important;
    }
}

// task - gantt
.gantt-task-details {
    min-width: 220px;
}

// task search
.task-search {
    .search-input {
        padding-left: 32px;
        z-index: 10;
    }
    .icon-search {
        z-index: 11;
        position: absolute;
        left: 10px;
        top: 10px;
    }
}

.cursor-pointer {
    cursor: pointer;
}



.filtre{
    position: relative; /* For positioning the pseudo-element */
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    padding: 0 0px 0px 0px;

}
.filtre div{
    padding: 0px 10px 5px 20px;
}
.med-gradient{
    background-image: linear-gradient(to right top, #012151, #0c3d76, #145c9c, #177dc3, #12a0eb);
}
.med-bar-gradient{
    background-image: linear-gradient(to bottom, #f9f9f9, #f1f3fb, #e5eefe, #d4eaff, #c0e7ff);
}
.med-bar-gradient-danger{
    background-image: linear-gradient(to bottom, #f6f6f6, #f3edf4, #f5e2ee, #fad7e0, #fdcdcd);
}

.filtre::before {
    /* Position the pseudo-element. */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    /* Create the box shadow at expanded size. */
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.4);

    /* Hidden by default. */
    opacity: 0.1;
    transition: opacity 1000ms;
}

.filtre:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
}

#currentTache{
    position: relative; /* For positioning the pseudo-element */
    box-shadow: 0px 3px 5px 0px #9E9E9E;
    border-radius:5px;
    background-image: linear-gradient(to right bottom, #f4f8ff, #ecf5ff, #e3f2ff, #d8f0ff, #cdeeff);
    margin-bottom: 50px;
    padding: 5px;
}